<template>
  <LocalizedInput
    component="VText"
    :model-value="value.new_names"
    :errors="v.new_names"
    :label="$t('app.name')"
    :lang="lang"
    field="new_names"
    class="col-span-2"
    @update:modelValue="onUpdate"
    @keyup.enter="onSave"
  />
</template>

<script>
import { ref, computed } from "vue";
import { required } from "@/i18n/i18n-validators";
import { useStore } from "@/store";
// Mixins
import FormMixin from "@/mixins/FormMixin";
// Composables
import useForm from "@/composables/useForm";

export default {
  mixins: [FormMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, context) {
    // Misc
    const store = useStore();

    // Data
    const value = ref({ new_names: "" });
    const rules = ref({ new_names: { required } });

    // Computed
    const langKeys = computed(() => {
      const langs = store.getters["app/available_languages"];
      return Object.keys(langs);
    });

    const lang = computed(() => {
      if (!props.data?.texts) {
        return "";
      }

      const langs = props.data.texts.reduce((acc, text) => {
        if (text.name && langKeys.value.includes(text.language)) {
          return [...acc, text.language];
        }

        return acc;
      }, []);

      if (langs.length === 1) {
        return langs[0];
      }

      return "";
    });

    // Composables
    const { v, onUpdate, onSave } = useForm(props, context, {
      value,
      rules,
      localizedFields: ["new_names"],
      lang
    });

    return {
      lang,
      value,
      // useForm
      v,
      onSave,
      onUpdate
    };
  }
};
</script>
