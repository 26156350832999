<template>
  <div>
    <VAction
      :disabled="disabled"
      :label="displayedLabel"
      icon="duplicate"
      @click="showModal"
    />

    <FormModal
      v-if="isModalVisible"
      :id="id"
      :is-loading="isDuplicating"
      @click:cancel="hideModal"
      @click:confirm="onClickSaveDuplicate"
    >
      <template #header>
        {{ $t("app.duplicate") }}
      </template>

      <template #content>
        <DuplicateForm ref="form" :data="item" @click:save="onClickDuplicate" />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { ref, inject, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useModal from "@/composables/useModal";
// Components
import VAction from "@/components/tables/VAction";
import FormModal from "@/components/modals/FormModal";
import DuplicateForm from "@/components/forms/DuplicateForm";

export default {
  components: {
    VAction,
    FormModal,
    DuplicateForm
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    item: {
      type: Object,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Inject
    const _ = inject("lodash");

    // Data
    const id = ref(_.uniqueId());
    const form = ref(null);

    // Composables
    const { isModalVisible, showModal, hideModal } = useModal(`#${id.value}`);
    const { request, isRequesting: isDuplicating } = useRequest();

    // Computed
    const displayedLabel = computed(() => {
      return props.label ? props.label : t("app.duplicate");
    });

    // Methods
    const onClickSaveDuplicate = async () => {
      await form.value.onSave();
    };

    const onClickDuplicate = async data => {
      const texts = JSON.parse(data.texts);
      const new_names = texts.reduce((acc, text) => {
        if (text.new_names) {
          return {
            ...acc,
            [text.language]: text.new_names
          };
        }

        return acc;
      }, {});

      await request({
        endpoint: `${props.endpoint}.duplicate`,
        pathParams: {
          id: props.item.id
        },
        data: {
          new_names: JSON.stringify(new_names)
        }
      });

      hideModal();

      context.emit("save");
    };

    return {
      id,
      form,
      displayedLabel,
      onClickDuplicate,
      onClickSaveDuplicate,
      // useModal
      isModalVisible,
      showModal,
      hideModal,
      // useRequest
      isDuplicating
    };
  }
};
</script>
