<template>
  <div>
    <VAction
      :disabled="isDisabled"
      :label="displayedLabel"
      icon="new-version"
      @click="showModal"
    />

    <FormModal
      v-if="isModalVisible"
      :id="id"
      :is-loading="isVersioning"
      @click:cancel="hideModal"
      @click:confirm="onClickSaveNewVersion"
    >
      <template #header>
        {{ $t("app.create_new_version") }}
      </template>

      <template #content>
        <NewVersionForm ref="form" @click:save="onClickNewVersion" />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { ref, inject, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useRequest from "@/composables/useRequest";
import useModal from "@/composables/useModal";
// Components
import VAction from "@/components/tables/VAction";
import FormModal from "@/components/modals/FormModal";
import NewVersionForm from "@/components/forms/NewVersionForm";

export default {
  components: {
    VAction,
    FormModal,
    NewVersionForm
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    item: {
      type: Object,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Inject
    const _ = inject("lodash");

    // Data
    const id = ref(_.uniqueId());
    const form = ref(null);

    // Composables
    const { isModalVisible, showModal, hideModal } = useModal(`#${id.value}`);
    const { request, isRequesting: isVersioning } = useRequest();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const displayedLabel = computed(() => {
      return isActive.value ? props.label : label.value;
    });

    const isActive = computed(() => {
      return props.item?.status === ALL_OPTIONS.ACTIVE.value;
    });

    const label = computed(() => {
      return isActive.value
        ? t("app.add_new_version")
        : t("app.disabled_new_version_msg");
    });

    const isDisabled = computed(() => {
      return !isActive.value || props.disabled;
    });

    // Methods
    const onClickSaveNewVersion = async () => {
      await form.value.onSave();
    };

    const onClickNewVersion = async data => {
      await request({
        endpoint: `${props.endpoint}.new-version`,
        pathParams: {
          id: props.item.id
        },
        data
      });

      hideModal();

      context.emit("save");
    };

    return {
      isDisabled,
      id,
      form,
      displayedLabel,
      onClickNewVersion,
      onClickSaveNewVersion,
      // useModal
      isModalVisible,
      showModal,
      hideModal,
      // useRequest
      isVersioning
    };
  }
};
</script>
