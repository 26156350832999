<template>
  <VText
    v-model="value.new_version"
    :label="$t('app.version')"
    :errors="v.new_version.$errors"
    @keyup.enter="onSave"
  />
</template>

<script>
import { ref } from "vue";
import { required } from "@/i18n/i18n-validators";
// Mixins
import FormMixin from "@/mixins/FormMixin";
// Composables
import useForm from "@/composables/useForm";

export default {
  mixins: [FormMixin],
  setup(props, context) {
    const value = ref({ new_version: "" });
    const rules = ref({ new_version: { required } });
    const { onSave, v } = useForm(props, context, { value, rules });

    return {
      value,
      // useForm
      v,
      onSave
    };
  }
};
</script>
