import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useOptions from "@/composables/useOptions";

export default function useSysAdminLocked(entity) {
  // Misc
  const store = useStore();
  const { t } = useI18n();

  // Composables
  const { ALL_OPTIONS } = useOptions();

  // Computed
  const isSysAdmin = computed(() => store.getters["user/isSysAdmin"]);

  // Methods
  const getActionLabel = (item, label) => {
    return isAuthorized(item) ? t(`app.${label}`) : getEntityLabel(entity);
  };

  const isSysAdminLocked = item => {
    return item?.sys_admin_locked === ALL_OPTIONS.YES.value;
  };

  const isAuthorized = item => {
    return (
      !isSysAdminLocked(item) || (isSysAdminLocked(item) && isSysAdmin.value)
    );
  };

  const isUnauthorized = item => !isAuthorized(item);

  const getEntityLabel = () => {
    // @TODO: add quiz and observation
    switch (entity) {
      case "competency":
        return t("app.sys_admin_locked_competency_msg");
      case "lesson":
        return t("app.sys_admin_locked_lesson_msg");
      default:
        return t("app.sys_admin_locked_msg");
    }
  };

  return {
    isSysAdmin,
    getActionLabel,
    isSysAdminLocked,
    isUnauthorized,
    isAuthorized
  };
}
