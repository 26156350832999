<template>
  <Evaluations
    :endpoint="endpoint"
    :route="route"
    :types="types"
    :search-fields="searchFields"
    :filters="filters"
    :headers="headers"
  />
</template>

<script>
import { useI18n } from "vue-i18n";
// Composables
import useQuiz from "@/composables/useQuiz";
// Components
import Evaluations from "../Evaluations";

export default {
  components: {
    Evaluations
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    // eslint-disable-next-line
    const documentTitle = `${t("app.quizzes", 2)} - ${t("app.evaluations")} - ${t("app.teaching")}`;

    // CUSTOM COMPOSABLES
    const {
      endpoint,
      route,
      types,
      searchFields,
      filters,
      headers
    } = useQuiz();

    return {
      documentTitle,
      // useQuiz
      endpoint,
      route,
      types,
      searchFields,
      filters,
      headers
    };
  }
};
</script>
